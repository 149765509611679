.overlay-box {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    border: 1px solid #ccc;                     /* Light gray border */
    border-radius: 8px;                         /* Rounded corners */
    padding: 12px;                              /* Padding on all sides */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);   /* Slight shadow for depth */
    max-width: 300px;                           /* Maximum width */
    z-index: 1000;                              /* High z-index to stay on top */
  }

.overlay-box p {
  margin:0;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
}