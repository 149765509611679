.loader-container {
    position: fixed; /* Use fixed positioning to keep it on screen regardless of scrolling */
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* high value to ensure it's on top */
    text-align: center;
    
    background-color: rgba(107, 103, 40, 0.5);
    border-radius: 4px;
    padding:10px;
}

.loader-container p {
    padding:0;
    margin:0;
    color:white;
}

.loader {
    border: 16px solid rgb(206, 187, 187); /* Light grey */
    border-top: 16px solid rgb(89, 90, 42); /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    pointer-events: none; /* This ensures that the spinner won't block clicks */
    margin:auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}